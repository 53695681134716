.carousel-icon {
    border: 3px solid #81C342;
    border-radius: 50%;
    width:40px;
    height: 40px;
    margin: 0 5px;
    cursor: pointer;
}

.carousel-icon-active {
    background-color: #81C342;
}
#carouselContainer {
    display:flex;
}
#imagecontainer {

}