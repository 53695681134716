.imagescrollimage {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    position: absolute;
    object-fit: cover;
    height: 80vh !important;
}

.imagescrollimage.fade-out {
    opacity: 0;
}